.App {
  text-align: center;
  background-size: cover !important;
  background-position: center center !important;
  position: relative;
  background-image: url('../img/main_bg.jpg');
}
.app_setting *:hover { cursor:move !important; }

p {
  color: gray;
}

.logo {
  width: 70%;
  height: auto;
  margin-top: 3%;
}

.hr {
  color: rgb(129, 143, 143);
  background-color: #F2F4F4;
  opacity: 0.2;
}

.container-content {
  width: 75%;
}
.container-menu {
  width: 25%;
}

.container {
  display: flex;
  flex-direction: row;
  height: 100vh; 
  width:  100vw;
}

.howdy {
  color: gray;
  font-weight: 600;
  font-size: 15px;
  margin-top: 20px;
}

.howdy-desc {
  font-size: 13px;
  color: gray;
  font-weight: 300;
}
.loading{
  display:flex;
  width:100%;
  height: 150vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
